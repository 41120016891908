import { Box } from "@mui/material";
import Image from "next/image";
import { useTranslation } from "next-i18next";

import StudioImage from "./images/studio.jpg";
import Room2Image from "./images/2_room.jpg";
import Room3Image from "./images/3_room.jpg";
import PremiumImage from "./images/premium.jpg";
import BoxStatus from "components/Modules/OfferCard/components/BoxStatus";
import theme from "theme";
import Template from "pages/Home/components/Template";
import { useMemo } from "react";
import { useRouter } from "next/router";
import { convertToApi } from "utils/conventersApi";
import useHover from "hooks/useHover";

const CategoriesItem = ({ text, image, onClick }) => {
  const [ref, isHovering] = useHover();

  return (
    <Box
      ref={ref}
      backgroundColor="white"
      borderRadius={4}
      flex="1"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        "& img": {
          transition: "all 0.5s ease",
        },

        "&:hover img": {
          transform: "scale(1.1)",
        },
      }}
    >
      <Box
        width="100%"
        height={320}
        position="relative"
        borderRadius={4}
        overflow="hidden"
      >
        <Image
          alt="Mountains"
          src={image}
          fill={true}
          sizes="100%"
          style={{
            objectFit: "cover",
          }}
        />

        <Box
          width="100%"
          height="100%"
          position="absolute"
          zIndex={1}
          sx={{
            transition: "all 0.5s ease",
            backgroundColor: isHovering ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0)",
          }}
        />
        <Box position="absolute" top={0} left={0} padding={2} zIndex={2}>
          <BoxStatus
            backgroundColor={theme.palette.primary.main}
            color={theme.palette.primary.main}
            text={text}
          />
        </Box>
      </Box>
    </Box>
  );
};

const Categories = () => {
  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation("common");
  const { t: tNavigation } = useTranslation(["navigation"]);

  const router = useRouter();

  const handleClick = (query) => {
    const clearData = convertToApi(query);

    router.push({
      pathname: `/${tNavigation("navigationPaths.search")}`,
      query: { ...clearData },
    });
  };

  const elements = useMemo(() => {
    const items = [
      {
        image: StudioImage,
        text: tCommon("Studio"),
        query: { rooms: [1, 1] },
      },
      {
        image: Room2Image,
        text: `2 ${tCommon("rooms.few")}`,
        query: { rooms: [2, 2] },
      },
      {
        image: Room3Image,
        text: `3 ${tCommon("rooms.few")}`,
        query: { rooms: [3, 3] },
      },
      {
        image: PremiumImage,
        text: `4 ${tCommon("rooms.few")}`,
        query: { rooms: [4, 4] },
      },
    ];
    return items;
  }, [tCommon]);

  return (
    <Template
      title={t("Choose an Apartment for Yourself.title")}
      subTitle={t("Choose an Apartment for Yourself.desc")}
      text={t("Small, large, luxurious...")}
      id={"categories"}
    >
      <Box
        columnGap={4}
        px={{
          xs: 4,
          md: 0,
        }}
        gridTemplateColumns={{ xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
        display="grid"
        gap={2}
      >
        {elements?.map((item) => (
          <CategoriesItem
            key={item.text}
            text={item.text}
            image={item.image}
            onClick={() => handleClick(item.query)}
          />
        ))}
      </Box>
    </Template>
  );
};

export default Categories;
